import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { ReactComponent as SVGSearch } from "./../assets/img/search.svg";
import { ReactComponent as SVGFilter } from "./../assets/img/filter.svg";
import { ReactComponent as SVGSort } from "./../assets/img/sort.svg";
import { ReactComponent as SVGProductView } from "./../assets/img/productview.svg";
import { ReactComponent as SVGImport } from "./../assets/img/Import.svg";
import { ReactComponent as SVGPriceChange } from "./../assets/img/PriceChange.svg";
import { ReactComponent as SVGSelectAll } from "./../assets/img/SelectAll.svg";
import { ReactComponent as SVGAdd } from "./../assets/img/add.svg";
import { ReactComponent as SVGCheck } from "./../assets/img/check.svg";
import { ReactComponent as SVGSelectNone } from "./../assets/img/SelectNone.svg";
import { ReactComponent as SVGSummary } from "./../assets/img/Summary.svg";
import { ReactComponent as SVGProcess } from "./../assets/img/process.svg";
import { useUpdateEffect } from "usehooks-ts";
import { useScrollDirection } from "../hooks/useScrollDirection";
import SearchBar from "./SearchBar";
import { ProductViewOverlay } from "./ProductViewOverlay";

interface HeaderProps {
  showSearch: boolean;
  fromPLP: boolean;
  ProductView: string;
  setProductView: React.Dispatch<SetStateAction<string>>;
  setSearchDescription: React.Dispatch<SetStateAction<string>>;
  handleClick_Search: () => void;
  handleClick_Filter: () => void;
  handleClick_Sort: () => void;
  handleClick_Approve: () => void;
  handleClick_Process: () => void;
  handleClick_AddItem: () => void;
  handleClick_Summary: () => void;
  handleClick_SelectAll: () => void;
  handleClick_SelectNone: () => void;
}

function Header({
  showSearch,
  fromPLP,
  ProductView,
  setProductView,
  setSearchDescription,
  handleClick_Search,
  handleClick_Filter,
  handleClick_Sort,
  handleClick_Approve,
  handleClick_Process,
  handleClick_AddItem,
  handleClick_Summary,
  handleClick_SelectAll,
  handleClick_SelectNone,
}: HeaderProps) {
  const [ShowProductViewOverlay, setShowProductViewOverlay] = useState<boolean>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const scrollDirection = useScrollDirection(100);

  const refOverlayTarget = useRef(null);

  const handleClick_Import = () => {
    window.open("/ImportImages", "_blank")?.focus();
  };

  const handleClick_PriceChange = () => {
    window.open("/PriceChange", "_blank")?.focus();
  };

  const handleClick_ProductView = () => {
    setShowProductViewOverlay(!ShowProductViewOverlay);
  };

  const renderFunctionListLeft = () => {
    const arrFunctionList = [
      {
        Content: (
          <li key="search" className="header-function-list btnHover" onClick={handleClick_Search}>
            <SVGSearch />
          </li>
        ),
      },
      {
        Content: (
          <li key="filter" className="header-function-list btnHover" onClick={handleClick_Filter}>
            <SVGFilter />
            <span className="mobile-only-hidden">Filter</span>
          </li>
        ),
      },
      {
        Content: (
          <li key="sort" className="header-function-list btnHover" onClick={handleClick_Sort}>
            <SVGSort />
            <span className="mobile-only-hidden">Sort</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="productView"
            className="header-function-list btnHover"
            onClick={handleClick_ProductView}
            ref={refOverlayTarget}
          >
            <SVGProductView />
            <ProductViewOverlay
              show={ShowProductViewOverlay}
              targetRef={refOverlayTarget}
              ProductView={ProductView}
              setProductView={setProductView}
            />
          </li>
        ),
      },
      {
        Content: (
          <li key="import" className="header-function-list btnHover" onClick={handleClick_Import}>
            <SVGImport />
            <span className="mobile-only-hidden">Import</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="priceChange"
            className="header-function-list btnHover"
            onClick={handleClick_PriceChange}
          >
            <SVGPriceChange />
            <span className="mobile-only-hidden">Price Change</span>
          </li>
        ),
      },
    ];
    return arrFunctionList.map((func, index) => func.Content);
  };

  const renderPCFunctionListLeft = () => {
    const arrFunctionList = [
      {
        Content: (
          <li key="search" className="header-function-list btnHover" onClick={handleClick_Search}>
            <SVGSearch />
          </li>
        ),
      },
      {
        Content: (
          <li key="filter" className="header-function-list btnHover" onClick={handleClick_Filter}>
            <SVGFilter />
            <span className="mobile-only-hidden">Filter</span>
          </li>
        ),
      },
      {
        Content: (
          <li key="sort" className="header-function-list btnHover" onClick={handleClick_Sort}>
            <SVGSort />
            <span className="mobile-only-hidden">Sort</span>
          </li>
        ),
      },
      {
        Content: (
          <li key="approve" className="header-function-list btnHover" onClick={handleClick_Approve}>
            <SVGCheck />
            <span className="mobile-only-hidden">Approve</span>
          </li>
        ),
      },
      {
        Content: (
          <li key="process" className="header-function-list btnHover" onClick={handleClick_Process}>
            <SVGProcess />
            <span className="mobile-only-hidden">Proccess</span>
          </li>
        ),
      },
      {
        Content: (
          <li key="addItem" className="header-function-list btnHover" onClick={handleClick_AddItem}>
            <SVGAdd />
            <span className="mobile-only-hidden">Add Item</span>
          </li>
        ),
      },
    ];

    return arrFunctionList.map((func, index) => func.Content);
  };

  const renderFunctionListRight = () => {
    const arrFunctionList = [
      {
        Content: (
          <li key="summary" className="header-function-list btnHover" onClick={handleClick_Summary}>
            <SVGSummary />
            <span className="mobile-only-hidden">Summary</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="selectAll"
            className="header-function-list btnHover"
            onClick={handleClick_SelectAll}
          >
            <SVGSelectAll />
            <span className="mobile-only-hidden">Select All</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="selectNone"
            className="header-function-list btnHover"
            onClick={handleClick_SelectNone}
          >
            <SVGSelectNone />
            <span className="mobile-only-hidden">Select None</span>
          </li>
        ),
      },
    ];

    return arrFunctionList.map((func, index) => func.Content);
  };

  const renderPCFunctionListRight = () => {
    const arrFunctionList = [
      // {
      //   Content: (
      //     <li className="header-function-list btnHover" onClick={handleClick_Summary}>
      //       <SVGSummary />
      //       <span className="mobile-only-hidden">Summary</span>
      //     </li>
      //   ),
      // },
      {
        Content: (
          <li
            key="selectAll"
            className="header-function-list btnHover"
            onClick={handleClick_SelectAll}
          >
            <SVGSelectAll />
            <span className="mobile-only-hidden">Select All</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="selectNone"
            className="header-function-list btnHover"
            onClick={handleClick_SelectNone}
          >
            <SVGSelectNone />
            <span className="mobile-only-hidden">Select None</span>
          </li>
        ),
      },
    ];

    return arrFunctionList.map((func, index) => func.Content);
  };

  useUpdateEffect(() => {
    if (ProductView !== "list") {
      switch (scrollDirection) {
        case "up":
          setHideHeader(false);
          break;
        case "down":
          setHideHeader(true);
          break;
        default:
          break;
      }
    }
  }, [scrollDirection]);

  return (
    <>
      <header className={`header-container sticky noselect ${hideHeader && "hidden"}`}>
        <div className="header-function-container">
          {fromPLP ? (
            <ul className="header-function-list-container">{renderFunctionListLeft()}</ul>
          ) : (
            <ul className="header-function-list-container">{renderPCFunctionListLeft()}</ul>
          )}
          {fromPLP ? (
            <ul className="header-function-list-container">{renderFunctionListRight()}</ul>
          ) : (
            <ul className="header-function-list-container">{renderPCFunctionListRight()}</ul>
          )}
        </div>
        <SearchBar show={showSearch} setSearchDescription={setSearchDescription} />
      </header>
    </>
  );
}

export default Header;
