import { MouseEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import imageCompression from "browser-image-compression";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "../../components/Header";
import LoadingComponent from "../../components/LoadingComponent";

interface fileObj {
  name: string;
  type: string;
  size: number;
  stream: ReadableStreamDefaultReader;
  preview: string;
  blob: File;
  uploadstatus: string;
}

function ImagesImportTool() {
  const types: string[] = ["png", "jpg", "jpeg", "webp"];
  const [files, setFiles] = useState<fileObj[]>([]);
  const [exportFiles, setExportFiles] = useState<fileObj[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Message, setMessage] = useState<string>("");

  const blobServiceClient: BlobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sp=racwd&st=2023-10-27T05:40:49Z&se=2029-12-31T13:00:00Z&spr=https&sv=2022-11-02&sr=c&sig=ETUtalkFm5kY48%2BAw1gEx7zJuVM0LLnx%2Fjn5ZmybpFc%3D`
  ); // create a blobServiceClient
  const exportBlobServiceClient: BlobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sp=racwdli&st=2024-05-27T07:13:23Z&se=2051-05-27T14:00:00Z&spr=https&sv=2022-11-02&sr=c&sig=vE6VLmzEIZA3MXm1mwuj0y2sj3ZqHO%2BV9XBqEGbpurk%3D`
  );
  const containerClient: ContainerClient = blobServiceClient.getContainerClient("images"); // create a containerClient
  const exportContainerClient: ContainerClient =
    exportBlobServiceClient.getContainerClient("imagesforexport");

  const compressFiles = (files: FileList) => {
    return new Promise<File[]>(function (resolve, reject) {
      let fileArr: File[] = Array.from(files);

      let compressedFileArr = Promise.all(
        fileArr.map(async (file) => {
          return await imageCompression(file, {
            maxSizeMB: 0.2, // max image size = 200kb
            maxWidthOrHeight: 300,
            useWebWorker: true,
          });
        })
      );
      resolve(compressedFileArr);
    });
  };

  const compressExportFiles = (files: FileList) => {
    return new Promise<File[]>(function (resolve, reject) {
      let fileArr: File[] = Array.from(files);

      let compressedFileArr = Promise.all(
        fileArr.map(async (file) => {
          return await imageCompression(file, {
            maxSizeMB: 0.05, // max image size = 200kb
            maxWidthOrHeight: 100,
            useWebWorker: true,
          });
        })
      );
      resolve(compressedFileArr);
    });
  };

  const isImageNameFormatValid = (imageName: string) => {
    const imageNameFormat = /^\d+[BLP]$/;

    // substring function is used to get rid of the file format in the image name
    return imageNameFormat.test(imageName.substring(0, imageName.indexOf(".")));
  };

  const updateUploadStatus = (files: fileObj[], filename: string, status: string) => {
    let currentFiles = files.map((file) => ({
      ...file,
      uploadstatus: file.name === filename ? status : file.uploadstatus,
    }));

    setFiles(currentFiles);
    return currentFiles;
  };

  const itemExist = async (itemno: string) => {
    const inputJSON = JSON.stringify({
      ItemNo: itemno,
    });

    const res = await runFetch(
      spURL_POST + "Portal_ImagesImportTool_Item_Verify",
      getReqOpt_POST(inputJSON)
    );
    const resObj = JSON.parse(res);

    if (resObj.status === "ERROR!") {
      return false;
    } else if (resObj.status === "OK!") {
      return true;
    }

    return false; // failsafe
  };

  const handleChange_FileUploader = async (uploadedFiles: FileList) => {
    setMessage("");
    if (files.length + uploadedFiles.length <= 30) {
      setIsLoading(true);
      let prevFile = files;
      await compressFiles(uploadedFiles)
        .then((compressedFileArr) => {
          let fileArr = compressedFileArr.map((compressedFile) => {
            return {
              name: compressedFile.name,
              type: compressedFile.type,
              size: compressedFile.size,
              stream: compressedFile.stream().getReader(),
              preview: URL.createObjectURL(compressedFile),
              blob: compressedFile,
              uploadstatus: "",
            };
          });

          for (let i = 0; i < fileArr.length; i++) {
            const file: fileObj = fileArr[i];

            if (isImageNameFormatValid(file.name)) {
              fileArr = updateUploadStatus(fileArr, file.name, "Ready for Upload");
            } else {
              fileArr = updateUploadStatus(fileArr, file.name, "ERROR!: Invalid image name.");
            }
          }

          setFiles(prevFile.concat(fileArr));
        })
        .then(async () => {
          await compressExportFiles(uploadedFiles).then((compressedFileArr) => {
            let fileArr = compressedFileArr.map((compressedFile) => {
              return {
                name: compressedFile.name,
                type: compressedFile.type,
                size: compressedFile.size,
                stream: compressedFile.stream().getReader(),
                preview: URL.createObjectURL(compressedFile),
                blob: compressedFile,
                uploadstatus: "Ready for Upload",
              };
            });

            setExportFiles(fileArr);
          });
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setMessage("ERROR!: File amount limit (30) exceeded.");
    }
  };

  const renderFileRows = () => {
    let fileElement: React.JSX.Element[] = [<></>];
    if (files.length > 0) {
      fileElement = files.map((file, index) => (
        <li
          key={index}
          data-name={file.name}
        >
          <div className="imagesimporttool-row file-row">
            <p>
              <img
                src={file.preview}
                alt={file.name}
              />
            </p>
            <p>{file.name}</p>
            <p>{file.size / 1000} kB</p>
            <p className={file.uploadstatus.includes("ERROR") ? "color-red" : "color-success"}>
              {file.uploadstatus}
            </p>
            <p>
              <FontAwesomeIcon
                className="color-red btnHover"
                size="2x"
                icon={regular("circle-xmark")}
                onClick={handleClick_RemoveImage}
              />
            </p>
          </div>
        </li>
      ));
    }
    return <>{fileElement}</>;
  };

  const handleClick_Process = async () => {
    setMessage("");
    let currentFiles = files;
    for (let i = 0; i < files.length; i++) {
      const file: fileObj = files[i];

      if (isImageNameFormatValid(file.name)) {
        let imageName = file.name.substring(0, file.name.indexOf("."));
        const itemno = imageName.substring(0, imageName.length - 1);

        if (await itemExist(itemno)) {
          try {
            const blobName = file.name; // Specify a default blob name if needed
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            await blobClient
              .uploadData(file.blob) // upload the image
              .then(async () => {
                const imageLink = `https://tmportal-gkbbgqeqe0euhtg2.z01.azurefd.net/images/${file.name}`;

                const inputJSON = JSON.stringify({
                  ItemNo: itemno,
                  ImageType: imageName.replace(itemno, ""),
                  ImageLink: imageLink,
                });
                const res = await runFetch(
                  spURL_POST + "Portal_ImagesImportTool_Item_UpdateImageLink",
                  getReqOpt_POST(inputJSON)
                );
                const resObj = JSON.parse(res);

                if (resObj.status === "ERROR!") {
                  currentFiles = updateUploadStatus(
                    currentFiles,
                    file.name,
                    "ERROR!: Database upload error."
                  );
                } else if (resObj.status === "OK!") {
                  currentFiles = updateUploadStatus(currentFiles, file.name, "OK!");
                } else {
                  currentFiles = updateUploadStatus(
                    currentFiles,
                    file.name,
                    "ERROR!: Server error."
                  );
                }
              })
              .then(async () => {
                const exportBlob = exportFiles.filter(
                  (exportFile: fileObj) => exportFile.name === file.name
                )[0];

                const exportBlobClient = exportContainerClient.getBlockBlobClient(exportBlob.name); // get the blob client
                await exportBlobClient.uploadData(exportBlob.blob); // upload the image
                const imageLink = `https://tmportal-gkbbgqeqe0euhtg2.z01.azurefd.net/imagesforexport/${exportBlob.name}`;

                // const inputJSON = JSON.stringify({
                //   ItemNo: itemno,
                //   ImageType: imageName.replace(itemno, ""),
                //   ImageLink: imageLink,
                // });
                // const res = await runFetch(
                //   spURL_POST + "Portal_ImagesImportTool_Item_UpdateImageLink",
                //   getReqOpt_POST(inputJSON)
                // );
                // const resObj = JSON.parse(res);

                // if (resObj.status === "ERROR!") {
                //   currentFiles = updateUploadStatus(
                //     currentFiles,
                //     file.name,
                //     "ERROR!: Database upload error."
                //   );
                // } else if (resObj.status === "OK!") {
                //   currentFiles = updateUploadStatus(currentFiles, file.name, "OK!");
                // } else {
                //   currentFiles = updateUploadStatus(
                //     currentFiles,
                //     file.name,
                //     "ERROR!: Server error."
                //   );
                // }
              })
              .catch((err) => {
                currentFiles = updateUploadStatus(currentFiles, file.name, "ERROR!: Upload error.");
              });
          } catch (error) {
            currentFiles = updateUploadStatus(currentFiles, file.name, "ERROR!: Upload error.");
          }
        } else {
          currentFiles = updateUploadStatus(currentFiles, file.name, "ERROR!: ItemNo not found.");
        }
      } else {
        currentFiles = updateUploadStatus(currentFiles, file.name, "ERROR!: Invalid image name.");
      }

      setFiles(currentFiles);
    }
  };

  const handleClick_RemoveImage = (event: MouseEvent<SVGSVGElement>) => {
    setMessage("");
    let selectedFileName: string = "";
    switch (event.currentTarget.nodeName.toUpperCase()) {
      case "SVG":
        selectedFileName =
          event.currentTarget.parentElement!.parentElement!.parentElement!.dataset.name!;
        break;
      case "PATH":
        selectedFileName =
          event.currentTarget.parentElement!.parentElement!.parentElement!.parentElement!.dataset
            .name!;
        break;
      default:
        break;
    }

    if (selectedFileName !== "") {
      setFiles(files.filter((file) => file.name !== selectedFileName));
      setExportFiles(exportFiles.filter((file) => file.name !== selectedFileName));
    }
  };

  const handleClick_Clear = () => {
    setMessage("");
    setFiles([]);
    setExportFiles([]);
  };

  return (
    <>
      <div className="imagesimporttool-container no-select">
        <div className="home-button-list">
          {/* limit number of images */}
          <FileUploader
            multiple={true}
            handleChange={handleChange_FileUploader}
            types={types}
            children={
              <div className="fileupload-area btnHover row-alignment">
                <FontAwesomeIcon
                  size="2x"
                  icon={solid("file-import")}
                />
                <p>
                  <strong>Click here or drop image(s) to upload</strong>
                </p>
              </div>
            }
          />
          <div>
            <p>B = Box, P = Product, L = Lifestyle</p>
            <p>Example: xxxxxxxxB.jpg</p>
          </div>
        </div>

        <p className="color-red">{Message}</p>

        <ul className="imagesimporttool-filelist">
          <li>
            <div className="imagesimporttool-row title-row">
              <p>
                <strong>Image</strong>
              </p>
              <p>
                <strong>Name</strong>
              </p>
              <p>
                <strong>Size</strong>
              </p>
              <p>
                <strong>Status</strong>
              </p>
              <p></p>
            </div>
          </li>
          {isLoading ? <LoadingComponent /> : renderFileRows()}
        </ul>
        <div className="home-button-list row-alignment">
          <Button onClick={handleClick_Clear}>Clear</Button>
          <Button onClick={handleClick_Process}>Process</Button>
        </div>
      </div>
    </>
  );
}

export default ImagesImportTool;
