import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Col, FormCheck } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

type FilterAccordionProps = {
  FilterName: string;
  Items: string[];
  FilterItems: string[];
  OnFilterChange: (list: string[]) => void;
  clearTriggered: boolean;
};

function FilterAccordion({
  FilterName,
  Items,
  FilterItems,
  OnFilterChange,
  clearTriggered,
}: FilterAccordionProps) {
  const [selectedFilterItems, setSelectedFilterItems] = useState<string[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const handleChange_FilterCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked: boolean = event.currentTarget.checked;
    const checkedValue: string = event.currentTarget.value;
    let tmpSelectedFilterItems: string[] = selectedFilterItems;
    if (isChecked) {
      tmpSelectedFilterItems = tmpSelectedFilterItems.concat([checkedValue]);
    } else {
      tmpSelectedFilterItems = selectedFilterItems.filter((item) => item !== checkedValue);
    }
    OnFilterChange(tmpSelectedFilterItems);
    setSelectedFilterItems(tmpSelectedFilterItems);
  };

  useEffect(() => {
    setSelectedFilterItems([]);

    if (isInitialLoad && FilterName === "Buyer") {
      setSelectedFilterItems([sessionStorage.getItem("userid")?.toUpperCase() || ""]);
      setIsInitialLoad(false);
    }
  }, [clearTriggered]);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="text-align-center">
          <b>{FilterName}</b>
        </Accordion.Header>
        <Accordion.Body className="accordion-scroll">
          {/* {Items.map((item, index) => ( */}
          {FilterItems.map((item, index) => (
            <Col key={index} data-item={item} value={item}>
              <FormCheck
                type="checkbox"
                // id={`default-checkbox-${FilterName + item.replace(" ", "-")}`}
                id={`default-checkbox-${FilterName + index}`}
                label={item}
                value={item}
                data-item={item}
                checked={selectedFilterItems.includes(item)}
                onChange={handleChange_FilterCheckbox}
              />
            </Col>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FilterAccordion;
