import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { Routes, Route, useParams } from "react-router-dom";
import Home from "./pages/Home/Home";
import SignIn from "./pages/Home/SignIn";
import NotFound from "./pages/NotFound/NotFound";
import store from "./store";
import ImagesImportTool from "./pages/ImageImportTool/ImagesImportTool";
import PriceChange from "./pages/PriceChange/PriceChange";
import ItemPLP from "./pages/ItemPLP/ItemPLP";
import ItemPDP from "./pages/ItemPDP/ItemPDP";

store.setState("isSignedIn", false);

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/SignIn" element={<SignIn />} />
      <Route path="/ImportImages" element={<ImagesImportTool />} />
      <Route path="/PriceChange" element={<PriceChange />} />
      {/* <Route path="/ViewItemList" element={<ItemPLP />} /> */}
      <Route path="/DetailsPage/:itemNo" element={<ItemPDP />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
