import { SetStateAction } from "react";
import React, { useState, useEffect } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { OnEnterKeyDown } from "../functions/OnEnterKeyDown";
import { handleChange_Input } from "../functions/HandleChangeInput";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

interface UserVerifyProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  handleClick_Verified_Approve: () => void;
}

function PopupUserVerify({ show, setShow, handleClick_Verified_Approve }: UserVerifyProps) {
  const [UserID, setUserID] = useState("");
  const [Password, setPassword] = useState("");
  const [SignInResponse, setSignInResponse] = useState("");

  const handleClose = () => {
    setSignInResponse("");
    setShow(false);
  };

  const handleClick_LogIn = async () => {
    // create inputJSON for sp
    const inputJSON: string = JSON.stringify({
      UserID: UserID,
      Password: Password,
    });

    const res = await runFetch(spURL_POST + "Portal_User_Verify", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setSignInResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);

      const usergroup = spResponse.Table[0].UserGroup;

      switch (usergroup) {
        case "ADMIN":
        case "BUYER MASTER":
          handleClick_Verified_Approve();
          handleClose();
          break;
        default:
          setSignInResponse("Invalid permissions.");
          break;
      }
    }
  };

  return (
    <>
      <div>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div
              onKeyDown={(e) => {
                OnEnterKeyDown(e, handleClick_LogIn);
              }}
            >
              <table className="signin-table">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <p>
                          <strong>UserID:</strong>
                        </p>
                        <input
                          id="signin-userID"
                          name="UserID"
                          onChange={(e) => handleChange_Input(e, setUserID)}
                        ></input>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <p>
                          <strong>Password:</strong>
                        </p>
                        <input
                          id="signin-password"
                          type="text"
                          className="form-password"
                          name="Password"
                          autoComplete="off"
                          onChange={(e) => handleChange_Input(e, setPassword)}
                        ></input>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="signin-login-button btnHover" onClick={handleClick_LogIn}>
                        Log In
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="error-message" id="SignInResponse">
                {SignInResponse}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="popup-button-container">
              <div>
                <Button onClick={handleClose}>Close</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default PopupUserVerify;
